import React from 'react';
import { frontAppUrl } from 'utils/siteConfig';

export const OnboardingLink = (props) => {
  const spe = props.spe || 'erectile_dysfunction';
  const url = `${frontAppUrl}/onboarding.html?spe=${spe}&refresh=true`

  return (
    <a rel="noopener noreferrer nofollow" href={url}>
      {props.children}
    </a>
  );
};
