import React from "react";
import Helmet from "react-helmet";
import Container from "react-bootstrap/Container";
import { OnboardingLink } from "components/Start/OnboardingLink";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Layout } from "components/common";
import "./styles.sass";
import ReinsuranceColumn from "../ReinsuranceColumn";
import FertilityReinsuranceColumn from "../FertilityReinsuranceColumn";
import DoctorsTalks from "../DoctorsTalks";
import TopContainerStart from "../TopContainerStart";
import StructuredWebPage from 'components/common/meta/StructuredWebPage';
import CharlesMeta from 'components/common/meta/CharlesMeta';
import Segment from 'utils/segment'

const IndexPage = ({ pageContext }) => {
  const { family, specialty } = pageContext;

  const ReinsuranceComponent = ({price, slug}) => {
    if(slug == 'fertilite') {
      return <FertilityReinsuranceColumn  price={price} />
    }
    else {
      return <ReinsuranceColumn price={price} />
    }
  }

  return (
    <>
      <Helmet defer={false} >
        <html className="html-class-small-font-page" />
        <meta name="robots" content="noindex" />
      </Helmet>
      <Layout showBars={false} showM6Banner={false}>
        <CharlesMeta
          data={{
            title: specialty.meta.title,
            description: specialty.meta.description,
          }}
          segmentCategory={Segment.CATEGORIES.START}
        />
        <StructuredWebPage
          title={specialty.h1}
          description={specialty.meta.description}
          showAggregateRating={false}
        />
        <div className="text-center text-md-left start-page w-100 consutler-step-two ">
          <TopContainerStart />
          <Container className="pb-3 pb-sm-5 main-container container-family-last-step">
            <Row>
              <Col xs={12} xl={{ span: 8 }} className="left-col">
                <div className="d-flex flex-column align-items-center">
                  <h1 className="section-title main-title text-center font-weight-normal ">
                    {" "}
                    {specialty.h1}{" "}
                  </h1>
                  <OnboardingLink spe={specialty.specialtySlug}>
                    <button className=" button-diagnostic my-5 d-xl-none ">
                      Démarrer le diagnostic
                    </button>
                  </OnboardingLink>
                  <DoctorsTalks data={specialty} />
                  <OnboardingLink spe={specialty.specialtySlug}>
                    <button className=" button-diagnostic my-5 d-none d-xl-block ">
                      Démarrer le diagnostic
                    </button>
                  </OnboardingLink>
                </div>
                <div className="start-general-separator-div mt-5">
                  <div className="start-general-separator d-xl-none flex-column"></div>
                </div>
              </Col>
              <Col className="col-separator" xs={0} xl={1}>
                <div className="start-general-separator-xl  "></div>
              </Col>
              <Col xs={12} xl={{ span: 3 }} className="right-col">
                <ReinsuranceComponent price={specialty.price} slug={specialty.slug} />
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    </>
  );
};
export default IndexPage;
